import React, {useContext, useEffect, useState} from 'react'
import {Breadcrumb, Button, Empty, Modal, Space, Tabs} from "antd";
import {matchRoutes} from 'react-router'
import {Link, useLocation} from 'react-router-dom'
import {
    AlertFilled, AlertOutlined,
    // MenuFoldOutlined,
    // MenuUnfoldOutlined,
} from "@ant-design/icons";
import routes from "../../routes.jsx";
import {ErrorContext} from "../../providers/error/error.jsx";
// import {AppContext} from "../../providers/app/app.jsx";

function Header() {
    // const {
    //     sidebarCollapsed,
    //     toggleSidebar
    // } = useContext(AppContext);
    const location = useLocation()
    const matchRouteList = matchRoutes(routes, location)
    const breadcrumbItems = matchRouteList ? matchRouteList.map(({route}) => {
        return {
            key: route.path,
            title: <Link to={route.path}>{route.breadcrumbName}</Link>,
        }
    }) : []
    return <>
        <Space style={{display: "flex"}}>
            {/*{React.createElement(sidebarCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {*/}
            {/*    className: 'trigger',*/}
            {/*    onClick: () => toggleSidebar()*/}
            {/*})}*/}
            <Breadcrumb
                items={breadcrumbItems}
            />
        </Space>
        <Space>
            <ErrorAlarmComponent/>
        </Space>
    </>
}

function ErrorAlarmComponent() {
    const [activeKey, setActiveKey] = useState(null);
    const {errorList, removeError, removeAllError} = useContext(ErrorContext);
    const [isAlarm, setIsAlarm] = useState(false)
    const [isAlarmFill, setIsAlarmFill] = useState(false)
    const [isModalShow, setIsModalShow] = useState(false)
    useEffect(() => {
        if (errorList.length) {
            setActiveKey(errorList[0].id)
            setIsAlarm(true)
        } else {
            setIsAlarm(false)
        }
    }, [errorList])
    useEffect(() => {
        const timer = setInterval(() => {
            setIsAlarmFill(val => !val)
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [])
    return <>
        {
            isAlarm ?
                <Button
                    type={"link"}
                    icon={
                        isAlarm ?
                            isAlarmFill ?
                                <AlertFilled style={{color: "red"}}/> :
                                <AlertOutlined style={{color: "red"}}/> :
                            <AlertOutlined style={{color: "black"}}/>
                    }
                    onClick={() => {
                        setIsModalShow(true)
                    }}
                /> : null
        }
        <Modal
            title={"Error Page"}
            open={isModalShow}
            footer={null}
            onOk={() => {
                setIsModalShow(false)
            }}
            onCancel={() => {
                setIsModalShow(false)
            }}
            closeIcon={null}
            width={768}
        >
            {isAlarm ?
                <Tabs
                    type="editable-card"
                    onChange={(key) => {
                        setActiveKey(+key)
                    }}
                    activeKey={activeKey}
                    onEdit={(targetKey, action) => {
                        if (action === "remove") {
                            removeError({id: targetKey})
                        }
                    }}
                    items={errorList.map(error => {
                        return {
                            label: error.title,
                            key: error.id,
                            children: JSON.stringify(error.content)
                        }
                    })}
                    hideAdd
                    tabBarExtraContent={<Button onClick={removeAllError}>Clear</Button>}
                /> :
                <Empty/>
            }
        </Modal>
    </>
}

export default Header
