import React, {createContext, useEffect, useState} from "react";
// import {getAppVersion} from "../../ipc-render/app-info.js";

export const AppContextDefaultValue = {
    sidebarCollapsed: true,
    version: "",
};
export const AppContext = createContext(AppContextDefaultValue)

const AppProvider = ({children}) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(AppContextDefaultValue.sidebarCollapsed)
    const [version,setVersion] = useState(AppContextDefaultValue.version)
    const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed)
    useEffect(() => {
        async function exec() {
            const appVersion = "v0.1.0"
            setVersion(appVersion)
        }
        exec()
    },[])

    return (
        <AppContext.Provider
            value={{
                sidebarCollapsed,
                setSidebarCollapsed,
                version,
                toggleSidebar,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider
