import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ErrorProvider} from "./providers/error/error";
import {ConfigProvider} from "antd";
import AppProvider from "./providers/app/app";
import {BrowserRouter} from "react-router-dom";
import DefaultLayout from "./layout/default";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppProvider>
                <ErrorProvider>
                    <ConfigProvider
                        // theme={{
                        //     algorithm: theme.defaultAlgorithm,
                        // }}
                    >
                        <DefaultLayout/>
                    </ConfigProvider>
                </ErrorProvider>
            </AppProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
