import React from 'react'
import {useLocation, useNavigate, useRoutes} from "react-router-dom";
import routes from "../../routes.jsx"
import {matchRoutes} from "react-router";
import {Button, Result} from "antd";

function MainContent() {
    const navigate = useNavigate();
    const location = useLocation()
    const matchRouteList = matchRoutes(routes, location)
    const routesElem = useRoutes(routes);

    // return <>
    // {
    //     JSON.stringify(matchRouteList)
    // }</>

    return matchRouteList ? routesElem : <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={() => {
            navigate("/")
        }}>Back Home</Button>}
    />
}

export default MainContent;

