import React, {useEffect, useState} from 'react'
import {getMarketPlaceToken} from "../../utils/cookies";
import {getSubscriptionStatus} from "../../apis/backend";
import {Button, Descriptions, notification, Spin} from "antd";
import {useNavigate} from "react-router-dom";

function Show() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})
    const [descriptionItems, setDescriptionItems] = useState([]);
    useEffect(() => {
        setDescriptionItems(
            [
                {
                    key: '1',
                    label: '訂閱狀態',
                    children: data.status
                },
                {
                    key: '2',
                    label: '訂閱者',
                    children: data.email,
                },
                {
                    key: '3',
                    label: '開始時間',
                    children: data.startAt
                },
                {
                    key: '4',
                    label: '結束時間',
                    children: data.endAt,
                },
                {
                    key: '5',
                    label: '訂閱產品',
                    children: data.appName
                },
                {
                    key: '5',
                    label: '訂閱方案',
                    children: data.planName
                },
            ]
        )

    },[data])
    useEffect(() => {
        async function init() {
            setIsLoading(true)
            try {
                const token = getMarketPlaceToken()
                const subscriptionStatus = await getSubscriptionStatus(token);
                setData(subscriptionStatus)
            } catch (e) {
                notification.error({
                    message: "查詢失敗",
                    description: `error message: ${e.response.data.message}`
                })
            }
            setIsLoading(false)
        }

        init()
    }, [])
    return <>
        <Spin spinning={isLoading}>
            <Descriptions title="訂閱狀態" items={descriptionItems}/>
            <Button onClick={() => {
                navigate("/subscription")
            }}>
                重新查詢訂閱狀態
            </Button>
        </Spin>
    </>
}

export default Show