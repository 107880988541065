import dayjs from "dayjs";
import Cookies from "js-cookie";

export function setMarketPlaceToken(token) {
    const thirtyMinuteAfter = dayjs().add(30, 'minute')
    Cookies.set('marketPlaceSubscriptionToken', token, {expires: thirtyMinuteAfter.toDate()})
}

export function getMarketPlaceToken() {
    return Cookies.get('marketPlaceSubscriptionToken')
}

export function setGraphAPIToken(token) {
    const thirtyMinuteAfter = dayjs().add(30, 'minute')
    Cookies.set('graphAPIToken', token, {expires: thirtyMinuteAfter.toDate()})
}

export function getGraphAPIToken() {
    return Cookies.get('graphAPIToken')
}