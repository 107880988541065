import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import queryString from "query-string";
import {getMarketPlaceToken, setMarketPlaceToken} from "../../utils/cookies";
import {getSubscriptionStatus} from "../../apis/backend";
import {notification, Spin} from "antd";

function Subscription() {
    const location = useLocation()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        async function redirectPage(token) {
            // get status
            let status = ""
            try {
                const data = await getSubscriptionStatus(token);
                status = data.status
            } catch (e) {
                notification.error({
                    message: "取得狀態失敗",
                    description: `error message: ${e.response.data.message}`
                })
            }
            switch (status) {
                case "pending": {
                    if (location.hash) {
                        navigate("/subscription/pending" + location.hash)
                    } else {
                        navigate("/subscription/pending")
                    }
                    break
                }
                case "subscribed":
                    navigate("/subscription/subscribed")
                    break
                case "unsubscribed":
                    navigate("/subscription/unsubscribed")
                    break
                default:
            }
            setIsLoading(false)
        }

        const parsed = queryString.parse(location.search);
        console.log(parsed);
        // if have token and save to cookies for 30 minutes
        let token = parsed.token
        if (token) {
            setMarketPlaceToken(token)
        } else {
            token = getMarketPlaceToken();
        }
        console.log('token', parsed.token)
        redirectPage(token)
    // eslint-disable-next-line
    }, [])
    return <>
        <Spin spinning={isLoading}>
            檢查訂閱狀態
        </Spin>
    </>
}

export default Subscription