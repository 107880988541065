import axios from "axios";

const backend = {
    domainUrl: process.env.REACT_APP_BASE_URL,
    baseUrl: function () {
        return this.domainUrl
    },
};

export async function getSubscriptionStatus(token) {
    const output = await axios({
        url: `/market-place/status`,
        baseURL: backend.baseUrl(),
        method: 'get',
        headers: {
            marketPlaceToken: token,
        }
    });
    const {data} = output
    return {
        "email": data.email,
        "status": data.status,
        "startAt": data.startAt,
        "endAt": data.endAt,
        "appName": data.appName,
        "planName": data.planName,
    }
}

export async function getEmailByGraphAPIToken(token) {
    const output = await axios({
        url: `/ms-graph/email`,
        baseURL: backend.baseUrl(),
        method: 'get',
        headers: {
            graphAPIToken: token,
        }
    });
    const {data} = output
    return data.email
}

export async function getLoginLink() {
    const output = await axios({
        url: `/login-path`,
        baseURL: backend.baseUrl(),
        method: 'get',
    });
    const {data} = output
    return data.url
}
export async function activateSubscription(marketPlaceToken, graphAPIToken) {
    const output = await axios({
        url: `/market-place/subscribe`,
        baseURL: backend.baseUrl(),
        method: 'post',
        headers: {
            marketPlaceToken: marketPlaceToken,
            graphAPIToken: graphAPIToken
        }
    });
    const {status} = output
    console.log('subscription output', output)
    return status === 201
}