import React, {useEffect, useState} from 'react'
import queryString from "query-string";
import {useLocation, useNavigate} from "react-router-dom";
import {getMarketPlaceToken} from "../../utils/cookies";
import {activateSubscription, getEmailByGraphAPIToken, getLoginLink} from "../../apis/backend";
import {Button, notification, Spin} from "antd";

function Pending() {
    const location = useLocation()
    const [isLogin, setIsLogin] = useState(0)
    const [graphAPIToken, setGraphAPIToken] = useState("")
    const [marketPlaceToken, setMarketPlaceToken] = useState("")
    useEffect(() => {
        const parsedHash = queryString.parse(location.hash);
        // if have accessToken and save to cookies for 30 minutes
        let {graphAPIToken: _graphAPIToken} = parsedHash
        if (_graphAPIToken) {
            setGraphAPIToken(_graphAPIToken)
            setIsLogin(1)
        } else {
            setIsLogin(2)
        }
        const _marketPlaceToken = getMarketPlaceToken();
        if (_marketPlaceToken) {
            setMarketPlaceToken(_marketPlaceToken)
        }
        // if no accessToken and should negative to login page
    }, [location.hash])
    return <>
        {isLogin === 0 ? <Spin/> : null}
        {isLogin === 1 ?
            <ActiveSubscriptionPage graphAPIToken={graphAPIToken} marketPlaceToken={marketPlaceToken}/> : null}
        {isLogin === 2 ? <LoginPage/> : null}
    </>
}

function ActiveSubscriptionPage({graphAPIToken, marketPlaceToken}) {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    async function handleActivate() {
        setIsLoading(true)
        try {
            const isActivate = await activateSubscription(marketPlaceToken, graphAPIToken)
            if (isActivate) {
                notification.success({
                    message: "啟用成功",
                    description: "啟用成功，稍後微軟會將訂購成功的明細寄到您的email中，等待收到email後授權開始啟用"
                })
            } else {
                notification.error({
                    message: "啟用失敗",
                    description: `請檢查訂購email是否與購買email相同`
                })
            }
        } catch (e) {
            notification.error({
                message: "啟用失敗",
                description: `error message: ${e.response.data.message}`
            })
        } finally {
            setIsLoading(false)
        }

    }

    useEffect(() => {
        async function init() {
            const email = await getEmailByGraphAPIToken(graphAPIToken);
            setEmail(email)
        }

        init()
    }, [graphAPIToken])
    return <>
        <div>
            登入者: {email}
        </div>
        <div>
            <Button loading={isLoading} onClick={() => {
                handleActivate()
            }}>啟用訂閱</Button>
            <Button onClick={() => {
                navigate("/subscription")
            }}>
                重新查詢訂閱狀態
            </Button>
        </div>

    </>
}

function LoginPage({marketPlaceToken}) {
    const [loginLink, setLoginLink] = useState("")
    useEffect(() => {
        async function init() {
            const link = await getLoginLink()
            setLoginLink(link)
        }

        init()
    }, [])
    return <>
        此操作為重要操作，需要先登入後才能繼續操作，請點選下方按鈕進行登入
        <div>
            <Button onClick={() => {
                window.location.replace(loginLink);
            }}>
                登入
            </Button>
        </div>
    </>
}

export default Pending