import React, {
    // useContext,
} from 'react'
import {Layout, theme} from "antd";
// import {AppContext} from "../providers/app/app.jsx";
import MainContent from "./main-content/main-content.jsx";
import MyHeader from './header/header.jsx'

const {
    Header,
    // Sider,
    Content,
    Footer} = Layout;

function DefaultLayout() {

    // const {
    //     sidebarCollapsed,
    //     version,
    //     toggleSidebar
    // } = useContext(AppContext);
    const {
        token: {
            colorBgContainer,
        }
    } = theme.useToken();
    return <>
        {/*<Layout hasSider>*/}
        <Layout>
            {/*<Sider*/}
            {/*    style={{*/}
            {/*        overflow: 'auto',*/}
            {/*        height: '100vh',*/}
            {/*        position: 'fixed',*/}
            {/*        left: 0,*/}
            {/*        top: 0,*/}
            {/*        bottom: 0,*/}
            {/*        zIndex: 1,*/}
            {/*    }}*/}
            {/*    trigger={null}*/}
            {/*    collapsedWidth={"80px"}*/}
            {/*    width={"256px"}*/}
            {/*    collapsible*/}
            {/*    collapsed={sidebarCollapsed}*/}
            {/*>*/}
            {/*    <MySider/>*/}
            {/*</Sider>*/}
            <Layout
                className="site-layout"
                // style={{
                //     marginLeft: sidebarCollapsed ? "80px" : "256px",
                // }}
            >
                <Header
                    style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        padding: '16px 32px 16px 16px',
                        background: colorBgContainer,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <MyHeader/>
                </Header>

                <Content
                    style={{
                        margin: '24px 16px 0',
                        overflow: 'initial',
                        minHeight: 587,
                        background: colorBgContainer,
                    }}
                >
                    <div
                        style={{
                            padding: 12,
                            // textAlign: 'center',
                        }}
                    >
                        <MainContent/>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    Dot Zero ©2023
                </Footer>
            </Layout>
        </Layout>
    </>
}

export default DefaultLayout
