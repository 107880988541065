import React, {createContext, useState} from 'react'
import {message} from "antd";

export const ErrorContext = createContext({});

class Error {
    constructor({id, title, content}) {
        this.id = id
        this.title = title
        this.content = content
        if (this.id === undefined) {
            this.id = new Date().getTime();
        }
    }

}

export function ErrorProvider({children}) {
    const [errorList, setErrorList] = useState([])
    const [messageApi, messageContextHolder] = message.useMessage();

    function addError({title, content}) {
        const cloneErrorList = JSON.parse(JSON.stringify(errorList))
        cloneErrorList.push(
            new Error({
                title,
                content
            })
        )
        setErrorList(cloneErrorList)
        messageApi.error(JSON.stringify(content).substring(8) )
    }

    function removeError({id}) {
        setErrorList((list) => {
            return list.filter(err => err.id !== id)
        })
    }

    function removeAllError() {
        setErrorList([])
    }

    return <ErrorContext.Provider value={{
        errorList,
        addError,
        removeError,
        removeAllError,
    }}>
        {messageContextHolder}
        {children}
    </ErrorContext.Provider>
}
