import React from 'react'
// import AuthStatus from "./pages/auth-status/auth-status.jsx";
// import ServiceMonitor from "./pages/service-monitor/service-monitor.jsx";
import {DesktopOutlined} from "@ant-design/icons";
import Subscription from "./pages/subscription/subscription";
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/login/login";
import Show from "./pages/subscription/show";
import Pending from "./pages/subscription/pending";
// import Setting from "./pages/setting/setting.jsx";
// import Dashboard from "./pages/dashboard/dashboard.jsx";
// import MachineState from "./pages/machine-state/machine-state";

class RouteElement {
    constructor(props) {
        this.path = props.path
        this.element = props.element
        this.menuName = props.menuName
        this.breadcrumbName = props.breadcrumbName
        this.hidden = props.hidden
        this.icon = props.icon
        this.children = props.children
        this.index = props.index
        if (this.children === undefined) {
            delete this.children
        }
    }

}

const routes = [
    new RouteElement({
        path: "/",
        hidden: true,
        element: <Dashboard/>,
    }),
    new RouteElement({
        path: "/login",
        hidden: true,
        element: <Login/>,
    }),
    new RouteElement({
        path: "/subscription",
        icon: <DesktopOutlined/>,
        menuName: "微軟訂閱",
        breadcrumbName: "微軟訂閱",
        children: [
            new RouteElement({
                index: true,
                element: <Subscription/>,
                icon: <DesktopOutlined/>,
                menuName: "查詢中",
                breadcrumbName: "查詢中",
                hidden: false,
            }),
            new RouteElement({
                path: "/subscription/pending",
                element: <Pending/>,
                icon: <DesktopOutlined/>,
                menuName: "等待確定",
                breadcrumbName: "等待確定",
                hidden: true,
            }),
            new RouteElement({
                path: "/subscription/subscribed",
                element: <Show/>,
                icon: <DesktopOutlined/>,
                menuName: "訂閱中",
                breadcrumbName: "訂閱中",
                hidden: true,
            }),
            new RouteElement({
                path: "/subscription/unsubscribed",
                element: <Show/>,
                icon: <DesktopOutlined/>,
                menuName: "取消訂閱",
                breadcrumbName: "取消訂閱",
                hidden: true,
            }),
        ],
    }),
    // new RouteElement({
    //     path: "/auth-status",
    //     element: <AuthStatus/>,
    //     icon: <UserOutlined/>,
    //     menuName: "授權顯示畫面",
    //     breadcrumbName: "授權顯示畫面",
    // }),
    // new RouteElement({
    //     path: "/machine-state",
    //     element: <MachineState />,
    //     icon: <RobotOutlined />,
    //     menuName: "機台即時狀態",
    //     breadcrumbName: "機台即時狀態",
    // }),
    // new RouteElement({
    //     path: "/setting",
    //     element: <Setting/>,
    //     icon: <SettingOutlined/>,
    //     menuName: "設定",
    //     breadcrumbName: "設定",
    // }),
    // new RouteElement({
    //     path: "/monitor",
    //     element: <ServiceMonitor/>,
    //     icon: <DesktopOutlined/>,
    //     menuName: "各項況",
    //     breadcrumbName: "各務狀況",
    //     children: [new RouteElement({
    //         path: "/monitor/test",
    //         element: <ServiceMonitor/>,
    //         icon: <DesktopOutlined/>,
    //         menuName: "各項服務狀況",
    //         breadcrumbName: "各項服務狀況",
    //     })]
    // }),
];
export default routes
